.index{
	&.desktop{
		&.video_on{
			nav{
				.menu_level_1{
					padding: 0;
				}
				.search{
					padding: 0 0 0 1em;
				}
			}
		}
	}
}

nav {
	@include navtop();
	width: 100%;
	position: relative;

	.menu_level_1 {
		display: table;
		padding: 0 0 0 1em;
		list-style: none;
		width: 100%;
		@include media(md) {
			display: block;
			text-align: center;
		}

		& > li {
			display: table-cell;
			position: relative;
			vertical-align: top;

			@include media(md) {
				display: inline-block;
			}

			& > a {
				color: $white;
				display: block;
				padding: 1em 0.5em;
				text-transform: uppercase;
				text-decoration: none;
				text-align: center;
				@include media(md) {
					padding: 0.5em;
				}
			}

			&.selected {
				cursor: default;
				background: $linkColor;
				& > a {
					color: $white;
				}
			}
		}

		a {
			display: block;
			text-decoration: none;
		}


		&.deep-3,
		&.deep-2 {
			& > li {
				&.selected {
					& > a {

						&:hover {
						}
					}
				}
			}
		}


		&.deep-3 {
			.menu_level_2 {
				& > li {
					&.selected {
						& > a {

							&:hover {
							}
						}
					}
				}
			}
		}

	}

	/* Всплывающее меню */
	.menu_level_2 {
		background: #fff;
		box-shadow: none;
		display: table;
		list-style: none;
		margin: -1px 0 0;
		min-width: 150px;
		padding: 0;
		position: absolute;
		opacity: 0;
		top: 100%;
		left: 0;
		z-index: 999;
		text-align: left;
		text-transform: none;
		visibility: hidden;
		border-radius: 0 0 $borderRadius $borderRadius;

		& > li {
			display: table-cell;
			padding: 0.5em 1em;

			.menu-item__photo {
				float: left;
				width: 50%;

				& > img {
					display: block;
					height: auto;
					max-width: 100px;
					width: 100%;
				}
			}

			.menu-item__description {
				float: right;
				width: 50%;

				& > span {
					font-size: 12px;
				}
			}

			.menu-item__header {

				& > span {
					color: $textColor + 50%;
					font-size: 12px;
					vertical-align: top;
				}
			}

			& > a {
				color: $textColor;
				display: block;
				white-space: nowrap;
			}
			ul {
				display: block;
				list-style: none;
				padding: 0.5em;

				& > li {
					& > a {
						color: $textColor;
						display: block;
						white-space: nowrap;
						&:hover{
							color: $linkColor--hover;
						}
					}

					&.selected {
						& > a {
							color: $linkColor--hover;
						}
					}
				}
			}
		}
	}
}

.desktop {

	nav {
		.menu_level_1 {
			position: relative;
			width: 100%;

			& > li {

				/* Показываем всплывашку на ховер */
				&.subcat {
					&:hover {
						.menu_level_2 {
							opacity: 1;
							transition: opacity 0.3s ease, transform 0.3s ease-out;
							visibility: visible;
							transform: translate3d(0, 0, 0);
							box-shadow: $module_top__box-shadow;
							border: 1px solid $borderColor;
						}
					}
				}

				&:not(.selected) {
					&:hover {
						background: $linkColor--hover + 30%;
						& > a {
						}
					}
				}
			}
		}


		.menu_level_2 {
			display: table-row;
			left: 0;
			transform-origin: center;
			transform: translate3d(0, 1em, 0);

			& > li {
				float: left;
				width: 100%;

				&.selected {
					& > a {
						color: $linkColor--hover;
					}
				}

				&:not(.selected) {
					& > a {
						&:hover {
							color: $linkColor--hover;
						}
					}
				}
			}
		}

		.menu_level_3 {
			& > li {
				&:not(.selected) {
					&:hover {
						& > a {
							color: $linkColor--hover;
						}
					}
				}
			}
		}

		.search {
			float: right;
			margin: 3px 0 0;
			padding: 0 1em;
			width: 250px;
			@include media(lg) {
				margin: 0;
				width: 100%;
			}
		}

		.menu-wrapper {
			float: left;
			width: calc(100% - 250px);
			@include media(lg) {
				width: 100%;
			}
		}
	}
}

